<template>
  <div         @mouseenter="expanded = true"
               @mouseleave="expanded = false">
    <v-navigation-drawer
        v-model="drawer"
        app
        clipped
        class="elevation-0"
        :width="expanded ? 300 : 70"
        color="white"

        elevation="0"
        style="border-right: 1px solid #e0e0e0"
    >
      <v-list-item :class="expanded ? 'pl-7' : 'row'" class=" py-8">
        <img src="/image/logo.png" :width="expanded ? 68 : 45" alt="" @click="reload">
      </v-list-item>


      <v-list dense nav>
        <v-list-item-group
            color="primary"
            v-model="navigations"
        >
          <template v-for="(item) in items">
            <v-list-item
                class="px-4 ma-0"
                :key="item.navigation"
                link
                :value="item.navigation"
                :to="item.route"
            >
              <v-list-item-icon class="my-4" v-if="$route.name">
                <v-icon size="24">{{ item.icon }}</v-icon>
              </v-list-item-icon>


              <v-list-item-content v-if="$route.name">
                <v-list-item-title style="font-size: 16px">{{ item.title }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action v-if="item.navigation =='notifications' && notifications_count">
                <div class="notification-count error white--text"
                >{{ notifications_count }}
                </div>

              </v-list-item-action>
            </v-list-item>
          </template>
          <v-list-item
              class="py-2 px-4 ma-0"
              link
              value="logout"
              @click="logout_dialog = true"
          >
            <v-list-item-icon>
              <v-icon size="24">mdi-logout</v-icon>
            </v-list-item-icon>

            <v-list-item-content v-if="$route.name">
              <v-list-item-title style="font-size: 16px">{{ $t('basics.logout') }}</v-list-item-title>
            </v-list-item-content>

          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-dialog width="500" v-model="logout_dialog">
      <v-card elevation="0" rounded="lg">
        <v-card-title>{{ $t('basics.logout') }}</v-card-title>
        <v-card-text>
          {{ $t('basics.logout_confirm') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="secondary" @click="logout_dialog = false" elevation="0">{{ $t('basics.no') }}</v-btn>
          <v-btn color="error" elevation="0" @click="logout">{{ $t('basics.yes') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "SideBar",
  props: {
    open: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      expanded: false,
      drawer: true,
      logout_dialog: false,
      navigations: null,
      notifications_count: 0,
      items: [
        {
          title: this.$t('components.sidebar.profile'),
          icon: "mdi-home-outline",
          route: '/profile',
          navigation: "profile"
        },
        {
          title: this.$t('components.sidebar.notifications'),
          icon: "mdi-bell-outline",
          route: '/notifications',
          navigation: "notifications"
        },
        {
          title: this.$t('components.sidebar.drugs'),
          icon: "mdi-medication-outline",
          route: '/drugs',
          navigation: "drugs"
        },
        {
          title: this.$t('components.sidebar.layouts'),
          icon: "mdi-file-document-check-outline",
          route: '/samples',
          navigation: "samples"
        },
        {
          title: this.$t('components.sidebar.patients'),
          icon: "mdi-account-multiple-outline",
          route: '/patients',
          navigation: "patients"
        },
        {
          title: this.$t('components.sidebar.statistics'),
          icon: "mdi-chart-line",
          route: '/statistics',
          navigation: "statistics"
        },
        // {title: "Kalendar", icon: "mdi-calendar-check", route: '/calendar'},
        // {title: "Chiqish", icon: "mdi-logout", route: '/login'},
      ],
    };
  },
  mounted() {
    if (this.$route.meta.layout)
      this.getNotifications()
  },
  watch: {
    '$route'(val) {
      this.navigations = val.meta.navigation
    },
    open(val) {
      this.drawer = val
    },
  },
  methods: {
    reload() {
      this.$router.push('/', () => window.location.reload())
    },
    async getNotifications() {
      // eslint-disable-next-line no-undef
      let res = await axios.get("/api/doctors/notifications/count", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      });
      if (res.data.ok)
        this.notifications_count = res.data.data
    },
    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login')
      })
    },
  }
}
</script>


<style scoped>
.text-color {
  color: #7F95A3 !important;
}

.text-size {
  font-size: 18px !important;
  line-height: 15px !important;
  padding: 4px 0 !important;
}

.logout_icon {
  font-size: 30px !important;
  margin: 0 10px 0 0 !important;
  padding: 0 !important;
  cursor: pointer;
  width: 20px !important;
  height: 20px !important;
  border-radius: 50%;
}

.notification-count {
  background-color: red;
  border-radius: 50rem;
  font-size: 16px;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
}

.logout_icon:hover {
  background: #bbb !important;
  color: white !important;
}

.active {
  background: #01A1B7 !important;
}

.active-color {
  color: white !important;
}

.row {
  display: flex;
  justify-content: center;
  margin: 0;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.flex-row {
  display: flex;
  justify-content: center;
  align-items: center !important;
}

.active-back {
  background: #01A1B7 !important;
  border-radius: 5px;
}

.mr0 {
  margin-right: 0 !important;
}

.v-overlay {
  z-index: 10003 !important;
}
</style>