<template>
  <component :is="resolveLayoutVariant">
    <!--    <v-slide-x-transition mode="out-in">-->
    <!--        <transition name="transition" mode="out-in" appear>-->
    <router-view v-if="!(not_found || forbidden)"></router-view>
    <page-not-found v-else-if="not_found"></page-not-found>
    <page-forbidden v-else-if="forbidden"></page-forbidden>

    <!--        </transition>-->
    <!--    </v-slide-x-transition>-->
  </component>
</template>

<script>
import LayoutBlank from '@/layouts/LayoutBlank';
import LayoutContent from '@/layouts/LayoutContent';
import LayoutRegister from '@/layouts/LayoutRegister';
import LayoutRecipe from '@/layouts/LayoutRecipe';
import PageNotFound from "@/pages/Page/PageNotAllowed.vue";
import PageForbidden from "@/pages/Page/PageForbidden.vue";

export default {
  name: 'App',

  components: {
    PageForbidden,
    PageNotFound,
    LayoutBlank,
    LayoutRecipe,
    LayoutRegister,
    LayoutContent,
    LayoutBase: () => import('@/layouts/LayoutBase'),
  },
  async mounted() {
    await this.check_user()
    let This = this
    window.axios.interceptors.response.use(function (response) {
      return response
    }, function (error) {
      if (error.response.status === 404) {
        This.not_found = true
      }
      if (error.response.status === 403) {
        This.forbidden = true
      }

      return Promise.reject(error)
    })
  },
  data() {
    return {
      not_found: false,
      forbidden: false,
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'ONLINEMED.uz';
        this.not_found = false
        this.forbidden = false
      }
    },
  },
  methods: {
    async check_user() {
      setTimeout(function () {
        if (!["login","register"].includes(this.$route.name))
        this.$store.dispatch('check_me').then((res) => {
          if (!res) {
            if (!['/not-auth', '/', '/login', '/register', '/register/doctor', '/register/region',
              '/forgot-password', '/new-password', '/password-code', '/privacy-policy', '/terms-of-use', '/not-yet'
            ].includes(this.$route.path)) {
              this.$router.push('/not-auth')
              this.$swal.fire({
                icon: 'error',
                title: 'Sizga ruxsat berilmagan',
                showConfirmButton: false,
                timer: 1500
              })
            }
          }
        })
      }.bind(this), 10)
    }
  },
  computed: {
    resolveLayoutVariant() {
      var route = this.$route
      var token = this.$store.getters.token
      var user = this.$store.getters.doctor
      if (route.meta.layout === 'base') return `layout-base`
      if (route.meta.layout === 'register') return `layout-register`
      if (route.meta.layout === 'blank' || !(token && user && user.viewed && user.accepted)) return 'layout-blank'
      if (route.meta.layout === 'recipe') return `layout-recipe`
      if (route.meta.layout === 'content' || token && user && user.viewed && user.accepted) return `layout-content`

      return null
    }
  },
};
</script>
<style>

</style>