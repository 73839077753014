<template>
    <v-app style="position: relative">
        <SideBar style="z-index: 152" ref="sidebar"/>
        <d-navbar style="z-index: 151; position:fixed; width: 100%; top: 0; background-color: #fff"/>
        <v-main style="margin-top: 68px;">
            <slot></slot>
        </v-main>
        <div class="overlay" :style="opened? 'top: 70px': 'top: 93%'">
          <v-btn block color="primary" elevation="0" @click="opened = !opened" rounded>Qabullar tarixi <v-icon>mdi-chevron-{{ opened ? 'down': 'up' }}</v-icon> </v-btn>
          <history-cards></history-cards>
        </div>
    </v-app>

</template>

<script>
import SideBar from "@/components/SideBar.vue";
import HistoryCards from "@/components/HistoryCards.vue";

export default {
    name: "LayoutRecipe",
    components: {
        HistoryCards,
        SideBar,
        DNavbar: () => import('@/components/DNavbar.vue')
    },
    data() {
        return {
          opened: false
        }
    }
}
</script>

<style scoped>
.menu-icon {
    position: fixed;
    top: 20px;
    z-index: 153;
    cursor: pointer;
    transition: 0.2s;
}

.menu-icon:hover {
    opacity: 0.8;
}

.menu-icon:active {
    opacity: 0.6;
}
.overlay{
  position: fixed;
  right: 0;
  bottom: 0;
  width: 320px;
  z-index: 100;
  border-left: 1px solid #e0e0e0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  top: 70px;
  overflow: hidden;
  transition: 300ms;
  animation-timing-function: ease-out;
  box-shadow: gray 0 0 10px 0;
}


</style>
