import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import VueTheMask from 'vue-the-mask'
import vueClickOutsideElement from 'vue-click-outside-element'
import store from './store'
import ax from 'axios'
import { Icon }  from 'leaflet'
import axios from "axios";
import i18n from "./i18n";
import VueSweetalert2 from 'vue-sweetalert2';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen';
import 'leaflet/dist/leaflet.css';
import FlagIcon from 'vue-flag-icon'
window.axios = ax;
window.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

Vue.use(VueTheMask)
Vue.use(vueClickOutsideElement)
Vue.use(VueSweetalert2);
Vue.use(FlagIcon)



Vue.config.productionTip = false
import 'leaflet/dist/leaflet.css'
import 'sweetalert2/dist/sweetalert2.min.css';

delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

axios.interceptors.request.use(function (config) {
  config.headers.Authorization = "Bearer " + store.getters.token;
  return config;
});

var hours = 18;
var now = new Date().getTime();
var setupTime = localStorage.getItem('setupTime');
var user = store.getters.doctor;

if (setupTime == null) {
  localStorage.setItem('setupTime', now)
} else {
  if(now-setupTime > hours*60*60*1000 && user && !  user.sent) {
    localStorage.clear();
    localStorage.setItem('setupTime', now);
    window.location.href = "/login";
  }
}

// import {debounce} from "@/store/debounce";
// Vue.config.errorHandler = debounce(function (err) {
//         // handle error
//         // `info` is a Vue-specific error info, e.g. which lifecycle hook
//         // the error was found in. Only available in 2.2.0+
//         axios.post("/api/error", {
//             err: err.stack,
//             type: 'doctor'
//         })
//     }, 500)

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401) {
    if (!['/not-auth', '/', '/login', '/register', '/register/doctor', '/register/region',
      '/forgot-password', '/new-password', '/password-code', '/privacy-policy', '/terms-of-use', '/not-yet'
    ].includes(router.currentRoute.path)) {
      // localStorage.clear();
      // window.location.href = "/"
    }
  }
  return Promise.reject(error)
})